import React, { useState } from "react";
import {
  Card as MuiCard,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button
} from "@mui/material";

import Image from "../common/Image";
import { useTranslation } from "react-i18next";

const GameCard = ({
  imageUrl,
  imageHeight,
  imageWidth,
  onClick,
  title,
  subtitle,
  description,
  isHotGame,
  alt
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const [isHovered, setHover] = useState(false);
  const { t } = useTranslation();

  return (
    <MuiCard
      variant="outlined"
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        borderRadius: 3,
        border: 0,
        height: isHotGame
          ? "220px"
          : mobileView
          ? "100px"
          : tabletView
          ? "125px"
          : "155px",
        width: imageWidth,
      }}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        overflow="hidden"
        sx={{ borderRadius: 3 }}
        height={imageHeight}
        width={imageWidth}
      >
        <Image
          src={imageUrl}
          sx={{
            position: "relative",
            top: 0,
            opacity: isHovered ? "0.3": "1"
          }}
          hovered={true}
          alt={alt}
          height={imageHeight}
          width={imageWidth}
        />
        {isHovered && !mobileView && (
          <Button
            size="sm"
            style={{
              position: "absolute",
              marginTop: "-100px",
              marginLeft: "22px",
              backgroundImage: 'url(/assets/img/red_small.png)'
            }}
            variant="primary"
          > 
            {t("home.playNow")}
          </Button>
      )}  
        {title && !mobileView && !tabletView && (
          <>
            <Typography
              color={"white"}
              fontSize={12}
              textAlign="left"
              fontWeight={500}
              sx={{
                position:"absolute",
                marginTop: "-8px",
                marginLeft: "13px",
                width: "150px",
                height: "15px",
                overflow: "hidden"
              }}
            >
              {title}
            </Typography>
          </>
        )}

        {title && (mobileView || tabletView) && (
          <>
            <Typography
              color={"white"}
              fontSize={11}
              textAlign="left"
              fontWeight={500}
              sx={{
                lineHeight: "12px",
                position:"absolute",
                // marginTop: "-35px",
                marginLeft: "5px",
                maxWidth: "70px",
                height: "24px",
                overflow: "hidden"
              }}
            >
              {title}
            </Typography>
          </>
        )}
      </Box>
      {title && subtitle && (
        <>
          {mobileView || tabletView && (
            <Box
              display="flex"
              flexDirection="column"
              p={1}
              justifyContent="center"
            >
              <Typography
                fontWeight={600}
                color={"white"}
                fontSize={7}
                textAlign="left"
              >
                {title}
              </Typography>
            </Box>
          )}
        </>
      )}
    </MuiCard>
  );
};

export default GameCard;
