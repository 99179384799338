import React, { useEffect } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {Container, Typography, Button, Box, useTheme, InputAdornment, IconButton, TextField} from "@mui/material";
import FormTextField from "../form/FormTextField";
import FormSelect from "../form/FormSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import {useGetBankAccountsQuery, useGetProfileQuery} from "../../features/api/userApiSlice";
import * as Constant from "../../features/constant";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import format from "date-fns/format";
import CopiedTooltip from "../common/CopiedTooltip";

const BankAccount = ({ usePlayerBanksMutation }) => {
    const navigate = useNavigate();
    const theme = useTheme();


    return (
        <Container>
            <Typography fontWeight="bold" color="white">
                Invite Friends
            </Typography>
            <br/>
            <Typography color="white">
                Share to your friends
            </Typography>
            <br/>
            <Typography fontWeight="bold" color="grey">
                Invitation URL
            </Typography>
            <TextField
                // label={t("walletcard.myaccountpage.referrallink")}
                // value={shareLink}
                InputLabelProps={{shrink: true}}
                disabled
                type="text"
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                sx={{
                                    "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <CopiedTooltip text={"shareLink"}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <img src="/assets/img/facebook.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/telegram.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/twitter.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
                <img src="/assets/img/whatsapp.png" style={{ height:'40px', width:'40px', marginRight: '15px' }}/>
            </div>

        </Container>
    );
};

export default BankAccount;
