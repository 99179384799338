import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DateRangePicker = ({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  size,
}) => {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="flex-start">
      <DatePicker
        label={t("walletcard.transactionpage.startdate")}
        value={startDate}
        onChange={handleStartDate}
        renderInput={(params) => <TextField {...params} size={size} />}
      />
      <Box ml="20px">
        <DatePicker
          label={t("walletcard.transactionpage.enddate")}
          value={endDate}
          onError={(error) => (error ? setIsError(true) : setIsError(false))}
          onChange={handleEndDate}
          renderInput={(params) => (
            <TextField
              {...params}
              error={isError}
              helperText={isError ? "Date should not be in the past" : null}
              size={size}
            />
          )}
          minDate={startDate}
        />
      </Box>
    </Box>
  );
};
DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func,
};

DateRangePicker.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
  handleStartDate: () => null,
  handleEndDate: () => null,
};

export default DateRangePicker;
