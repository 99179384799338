import React, { useState, useEffect } from "react";
import {Box, Menu, Typography, useMediaQuery, useTheme} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import { darken } from "@material-ui/core";
import * as Constant from "../../features/constant";
import WalletCard from "./WalletCard";

const LanguageDropdown = ({ languages }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleLanguageChange = () => {
      localStorage.setItem("language", i18n.language);

      if (process.env.REACT_APP_LANG_PATH_PARAM) {
        let strArr = window.location.pathname.replace("/","").split("/");
        let pathname = window.location.pathname;
        let langParam = Constant.COUNTRY_LANGUAGE_PATH_PARAMS[process.env.REACT_APP_COUNTRY][i18n.language];

        if (strArr.length > 1) {
          window.location.href = "/" + langParam + "/" + pathname.replace("/" + strArr[0] + "/", "");
        }
        else {
          window.location.href = "/" + langParam + pathname.replace("/" + strArr[0], "");
        }
      }
      else {
        window.location.reload();
      }
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const handleOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderItems = () => (
    <Box display="flex" flexDirection="column" px={2}>
      <Typography color="white">{t("translation.country")}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
      >
        <Image width="20px" height="20px" src={process.env.REACT_APP_BD_ICON} />
        <Box display="flex" pl={1}>
          {languages?.map((language) => (
            <Typography
              px={1}
              sx={{
                cursor: "pointer",
                color:
                  i18n.language.toUpperCase() === language.code
                    ? theme.palette.primary.main
                    : "white",
              }}
              onClick={() => {
                i18n.changeLanguage(language.code?.toLowerCase());
                handleClose();
              }}
            >
              {language?.label}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );

  const renderMenu = () => (
    <Menu
      sx={{ mt: "40px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {renderItems()}
    </Menu>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        pl: 1,
      }}
      onClick={handleOpen}
    >

        {/*Mobile View Wallet Card */}
        <Image width="30px" height="30px" src={process.env.REACT_APP_BD_ICON} />
        {!mobileView  && (
            <KeyboardArrowDown />
        )}
      {renderMenu()}
    </Box>
  );
};

export default LanguageDropdown;
