import React, { useRef, useEffect } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {Container, Typography, Grid, Button, Box, useTheme, List, ListItem,ListItemText,ListItemAvatar,Avatar, Divider } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import FormTextField from "../form/FormTextField";
import FormSelect from "../form/FormSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import {useGetBankAccountsQuery, useGetProfileQuery} from "../../features/api/userApiSlice";
import * as Constant from "../../features/constant";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import format from "date-fns/format";
import CopiedTooltip from "../common/CopiedTooltip";
import {AccountCircle as AccountCircleIcon} from "@mui/icons-material";
import i18n from "i18next";

const BankAccount = ({ usePlayerBanksMutation,progress }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const PATH = process.env.PUBLIC_URL;

    const handleClick = (item) => {
        // Handle click action for the item
        console.log(`Clicked ${item}`);
    }

    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const maxSize = Math.min(canvas.width, canvas.height);
        const radius = maxSize * 0.4;
        const startAngle = (3/2) * Math.PI; // Start angle at 12 o'clock position
        const endAngle = startAngle + (25 / 100) * Math.PI * 2 ; // 25% of circle (π/2 radians)
        const lineWidth = maxSize * 0.1;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, Math.PI * 2, false);
        context.strokeStyle = 'lightgrey';
        context.lineWidth = lineWidth;
        context.stroke();

        context.beginPath();
        context.arc(centerX, centerY, radius, startAngle, endAngle, false);
        context.strokeStyle = '#ee2842';
        context.lineWidth = lineWidth;
        context.stroke();

        // Draw percentage text
        context.fillStyle = 'grey';
        context.font = `${maxSize * 0.2}px Arial`; // Adjust font size as needed
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(`${25}%`, centerX, centerY);
    }, [progress]);




    return (
        <Container>
            <Typography fontWeight="bold" color="white">
            Security Center
            </Typography>
            <br/>
            <Grid container spacing={2} sx={{backgroundColor:'#FFFFFF',borderRadius:'5px'}}>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        {/* Canvas circle progress bar */}
                        <Grid item xs={6}>
                            <canvas ref={canvasRef} width={160} height={160}/>
                        </Grid>
                        {/* Column with four rows of sentences */}
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid container spacing={1} direction="column">
                                <Grid item>
                                    <Typography
                                    sx={{fontSize:'16px', fontWeight:'bold'}}
                                    >Security Level</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{fontSize:'14px', fontWeight:'bold', color:'red'}}>thunder</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{fontSize:'14px', fontWeight:'bold', color:'grey'}}>Last Login IP: 192.168.0.0</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{fontSize:'14px', fontWeight:'bold', color:'grey'}}>Last login time: 2024-04-07 13:05:41</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List>
                        <ListItem button onClick={() => handleClick('Item 1')}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Personal Information</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Complete Your Personal Information</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={() => handleClick('Item 2')}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{fontWeight: 'bold', color: 'white'}}>ID Verification</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px'}}>Verify Your Profile Now</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={() => handleClick('Item 3')}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Change Password</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Recommend Letter and Number Combination</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={() => handleClick('Item 4')}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Mobile Verification</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Mobile Verification</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                        <ListItem button onClick={() => handleClick('Item 5')}>
                            <ListItemAvatar>
                                <Avatar alt="Item 1 Image" src="/path/to/image1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold', color: 'white' }}>Email Verification</span>}
                                secondary={<span style={{ color: 'lightgrey', fontSize:'12px' }}>Email Verification</span>}
                            />
                        </ListItem>
                        <Divider component="li" style={{ backgroundColor: 'lightgrey' }}/>
                    </List>
                </Grid>
            </Grid>

        </Container>
    );
};

export default BankAccount;
