import React from "react";

const InformationIcon = ({ width, height, color, onMouseEnter, onMouseLeave }) => {
    return (
        <svg
            class="cursor-pointer tooltip_icon"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                cursor: "pointer"
            }}
        >
            <path
                d="M10.8,10.8H9.2V6h1.6m0,8H9.2V12.4h1.6M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z"
                transform="translate(-2 -2)"
                fill={color}
            ></path>
        </svg>
    );
};

export default InformationIcon;



