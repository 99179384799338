import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AntiScam = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container sx={{color: "white"}}>
      <Box my={4}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.antiscam.1.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.antiscam.1.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.antiscam.1.seconddesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.antiscam.1.thirddesc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.antiscam.2.title")}
        </Typography>

        <Typography variant="body2" component="div">
          {t("responsiblegaming.antiscam.2.firstdesc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div">
          {t("responsiblegaming.antiscam.2.seconddesc")}
        </Typography>
      </Box>
    </Container>
  );
};

export default AntiScam;
