import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
  darken,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Image from "../components/common/Image";
import { useGetCommonBackgroundQuery } from "../features/api/publicApiSlice";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  return (
    <>
      {mobileView && (
        <>
          <Grid
            container
            justify="center"
            py={1}
            backgroundColor={darken(theme.palette.background.paper, 0.4)}
          >
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("floatingbutton.contactus")}
              </Typography>
            </Grid>
          </Grid>
          <Container
            mt={0}
            sx={{
              minHeight: "120vh",
              backgroundColor: "black",
            }}
          >
            {!isCommonBackgroundLoading && isCommonBackgroundSuccess && (
              <Box pt={3}>
                {/* <Grid
                  container
                  px={2}
                  backgroundColor={theme.palette.background.paper}
                > */}
                  {Object.keys(commonBackground?.result?.contactUs)?.map(
                    (contact, index) => (
                      <Grid item xs={12} sx={{
                        marginBottom: "20px",
                        background: "#525252",
                        borderRadius: "10px",
                        padding: "10px"
                      }}>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            ...(index <
                              Object.keys(commonBackground?.result?.contactUs)?.length -
                                1 && {
                              // borderBottom: "1px solid grey",
                            }),
                            py: 1,
                          }}
                          onClick={() => window.open(commonBackground?.result.contactUs[contact].url, "_blank")}
                        >
                          <Image
                            height="50px"
                            width="50px"
                            src={commonBackground?.result.contactUs[contact]?.icon}
                          />
                          <Box sx={{
                            color: "white",
                            pl: "10px"
                          }}>
                              <Typography sx={{
                                fontWeight: "600",
                                fontSize: "18px"
                              }}>{commonBackground?.result.contactUs[contact]?.name}</Typography>
                              <Typography>{commonBackground?.result.contactUs[contact]?.value}</Typography>
                          </Box>
                          {/* <Typography pl={2} color="white">
                          {commonBackground?.result[contact]?.name}
                            {commonBackground?.result[contact]?.value}
                          </Typography> */}
                        </Box>
                      </Grid>
                    )
                  )}
                {/* </Grid> */}
              </Box>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default ContactUs;
