import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  createTheme,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import AccordionComponent from "../components/common/Accordion";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box backgroundColor="#1E1F22">
      {mobileView && (
        <>
          <Grid container justify="center" pt={1} pb={5}>
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("t&c.title")}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Container>
        {/* Terms */}
        <Box py={!mobileView && 4} mb={mobileView && 4}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight={800}
            color={theme.palette.primary.main}
          >
            {t("t&c.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.desc")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.1.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            1.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            1.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            1.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            1.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            1.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            1.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.1.1.6")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.2.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            2.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.2.2.1")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.3.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.firstdesc")}
            <br />
            <br />
            {t("t&c.3.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.6")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.7")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.8")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.9")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.10
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.10")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            3.11
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.3.3.11.firstdesc")}
            <br /> <br />
            {t("t&c.3.3.11.seconddesc")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.4.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            4.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            4.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            4.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.4.4.3")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.5.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            5.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.6")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7.firstdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7.thirddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7.fourthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.7.fifthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.8")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.9")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.10
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.10")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.11
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.11")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            5.12
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.5.5.12")}
          </Typography>
          <br />
          <Typography
            variant="body2"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.6.title")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            6.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.6.6.6")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            Collection of Winnings
          </Typography>
          <Typography variant="body2" component="div" color="white">
            7.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            7.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            7.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            7.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            7.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            7.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.7.7.6")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.8.title")}
          </Typography>{" "}
          <Typography variant="body2" component="div" color="white">
            8.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.6")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.7")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.8")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.9")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.10
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.10")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.11
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.11")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.12
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.12")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.13
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.13")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.14
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.14")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.15
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.15")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.16
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.16")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.17
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.17")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.18
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.18")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            8.19
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.8.8.19")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.9.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            9.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            9.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            9.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            9.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            9.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            9.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.firstdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.thirddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.fourthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.fifthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.sixthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.seventhdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.9.9.6.eightdesc")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.10.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            10.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.10.10.1")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.11.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            11.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.4")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.5
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.5")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.6
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.6")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.7
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.7")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.8
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.8")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            11.9
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.11.11.9")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.12.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            12.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            12.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            12.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.firstdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.thirddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.fourthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.fifthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.sixthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.seventhdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.eighthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.ninthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.tenthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.eleventhdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.12.12.3.twelfthdesc")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.13.title")}
          </Typography>
          <AccordionComponent title={"13.1 " + t("t&c.13.13.1.title")}>
            {t("t&c.13.13.1.desc")}
          </AccordionComponent>
          <AccordionComponent title={"13.2 " + t("t&c.13.13.2.title")}>
            {t("t&c.13.13.2.firstdesc")}
            <br />
            <br />
            {t("t&c.13.13.2.seconddesc")}
            <br />
            <br />
            {t("t&c.13.13.2.thirddesc")}
            <br />
            <br />
            {t("t&c.13.13.2.fourthdesc")}
          </AccordionComponent>
          <AccordionComponent title={"13.5 " + t("t&c.13.13.3.title")}>
            {t("t&c.13.13.3.desc")}
          </AccordionComponent>
          <AccordionComponent title={"13.4 " + t("t&c.13.13.4.title")}>
            {t("t&c.13.13.4.firstdesc")}
            <br />
            <br />
            {t("t&c.13.13.4.seconddesc")}
          </AccordionComponent>
          <AccordionComponent title={"13.5 " + t("t&c.13.13.5.title")}>
            {t("t&c.13.13.5.firstdesc")}
            <br />
            <br />
            {t("t&c.13.13.5.seconddesc")}
            <br />
            <br />
            {t("t&c.13.13.6.thirddesc")}
          </AccordionComponent>
          <AccordionComponent title={"13.6 " + t("t&c.13.13.6.title")}>
            {t("t&c.13.13.6.desc")}
          </AccordionComponent>
          <AccordionComponent title={"13.7 " + t("t&c.13.13.7.title")}>
            {t("t&c.13.13.7.desc")}
          </AccordionComponent>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.14.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.firstdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.thirddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.fourthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.fifthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.sixthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.seventhdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.eighthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.ninthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.tenthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.eleventhdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.twelfthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.thirteenthdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            14.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.14.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            14.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.14.14.2")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.15.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            15.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            15.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.2")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            15.3
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.3")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            15.4
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.15.15.4")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.16.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            16.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.16.16.1")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.17.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            17.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            17.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.2.firstdesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.2.seconddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.2.thirddesc")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            {t("t&c.17.17.2.fourthdesc")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.18.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            18.1
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.18.18.1")}
          </Typography>
          <br />
          <Typography variant="body2" component="div" color="white">
            18.2
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.18.18.2")}
          </Typography>
          <br />
          <Typography
            variant="body1"
            component="div"
            color="white"
            fontWeight={800}
          >
            {t("t&c.19.title")}
          </Typography>
          <Typography variant="body2" component="div" color="white">
            {t("t&c.19.desc")}
          </Typography>
          <AccordionComponent title={"19.1 " + t("t&c.19.19.1.title")}>
            {t("t&c.19.19.1.firstdesc")}
            <br />
            <br />
            {t("t&c.19.19.1.seconddesc")}
            <br />
            <br />
            {t("t&c.19.19.1.thirddesc")}
            <br />
            <br />
            {t("t&c.19.19.1.fourthdesc")}
            <br />
            <br />
            {t("t&c.19.19.1.fifthdesc")}
            <br />
            <br />
            {t("t&c.19.19.1.sixthdesc")}
            <br />
            <br />
            {t("t&c.19.19.1.seventhdesc")}
            <br />
            <br />
            {t("t&c.19.19.1.eighthdesc")}
          </AccordionComponent>
          <AccordionComponent title={"19.2 " + t("t&c.19.19.2.title")}>
            {t("t&c.19.19.2.firstdesc")}
            <br />
            <br />
            {t("t&c.19.19.2.seconddesc")}
          </AccordionComponent>
          <AccordionComponent title={"19.3 " + t("t&c.19.19.3.title")}>
            {t("t&c.19.19.3.firstdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.seconddesc")}
            <br />
            <br />
            {t("t&c.19.19.3.thirddesc")}
            <br />
            <br />
            {t("t&c.19.19.3.fourthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.fifthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.sixthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.seventhdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.eighthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.ninthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.tenthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.eleventhdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.twelfthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.thirteenthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.fourteenthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.fifteenthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.sixteenthdesc")}
            <br />
            <br />
            {t("t&c.19.19.3.seventeenthdesc")}
          </AccordionComponent>
        </Box>
      </Container>
    </Box>
  );
};

export default Terms;
