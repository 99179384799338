import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Keyboard } from "swiper";
import PropTypes from "prop-types";
import { Box, Card, CardContent, CardMedia, Typography, Link } from "@mui/material";

const MobilePopupCarousel = ({ data, slidesPerView, spaceBetween, navigation, pagination }) => {
  return (
    <Swiper
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation={navigation}
      pagination={pagination ? { clickable: true } : false}
      keyboard={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Navigation, Pagination, Keyboard]}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <Card
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '75vh',
              margin: 'auto',
              maxWidth: '600px',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box padding="10px">
              <Link href={item.link} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  component="img"
                  image={item.imageUrl}
                  alt={item.title}
                  sx={{
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: 1,
                  }}
                />
              </Link>
            </Box>
            <CardContent
              sx={{
                padding: 2,
                textAlign: 'left',
                color: 'white',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="subtitle1"
                component="h2"
                sx={{ color: 'white', wordWrap: 'break-word', fontSize: '1.2rem' }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  wordWrap: 'break-word',
                  marginTop: 1,
                  flex: 1,
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </CardContent>
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

MobilePopupCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  navigation: PropTypes.bool,
  pagination: PropTypes.bool,
};

MobilePopupCarousel.defaultProps = {
  slidesPerView: 1,
  spaceBetween: 20,
  navigation: true,
  pagination: true,
};

export default MobilePopupCarousel;
