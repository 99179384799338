import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Box,
  Grid,
  useTheme,
  Button,
  Typography,
  darken,
} from "@mui/material";
import Image from "../common/Image";
import * as Constant from "../../features/constant";
import { useNavigate } from "react-router-dom";

const GameProviderDrawer = ({
  window,
  gameProviderDrawer,
  setGameProviderDrawer,
  gameProvider,
  currentProviderId,
  setOffset,
  setCurrentProviderId,
  gameCategory
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setGameProviderDrawer(false);
  };

  const renderDrawerContent = () => (
    <Grid container spacing={2} p={5}>
      {gameProvider?.map((provider, index) => (
        <Grid
          item
          xs={4}
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              borderRadius: 4,
              height: 50,
              width: 100,
              borderColor: theme.palette.background.paper,
              bgcolor:
                currentProviderId === provider.id
                  ? theme.palette.secondary.main
                  : darken(theme.palette.background.paper, 0.02),
              fontWeight: 600,
              color: "black",
            }}
            onClick={() => {
              setOffset(1);
              setCurrentProviderId(provider?.id);
              setGameProviderDrawer(false);
              navigate(Constant.getPath("/game/" + gameCategory.toLowerCase().replace("_", "-") + "/" + provider.name.toLowerCase().replace(" ", "-")))
            }}
          >
            <Image src={provider?.mobileLogoUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider?.name}/>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Drawer
      container={container}
      anchor="bottom"
      variant="temporary"
      open={gameProviderDrawer}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          // width: drawerWidth,
          borderRadius: 3,
          bgcolor: theme.palette.background.paper,
        },
      }}
    >
      {renderDrawerContent()}
    </Drawer>
  );
};

GameProviderDrawer.propTypes = {
  gameProviderDrawer: PropTypes.func,
  setGameProviderDrawer: PropTypes.func,
  gameProvider: PropTypes.array,
  currentProviderId: PropTypes.number,
  setOffset: PropTypes.func,
  setCurrentProviderId: PropTypes.func,
  gameCategory: PropTypes.string
};

GameProviderDrawer.defaultProps = {
  gameProviderDrawer: () => null,
  setGameProviderDrawer: () => null,
  gameProvider: [],
  currentProviderId: null,
  setOffset: () => null,
  setCurrentProviderId: () => null,
  gameCategory: null
};

export default GameProviderDrawer;
