import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {Container, Typography, Grid, Button, Box, useTheme, InputAdornment, IconButton, TextField} from "@mui/material";
import FormTextField from "../form/FormTextField";
import FormSelect from "../form/FormSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import {useGetBankAccountsQuery, useGetProfileQuery} from "../../features/api/userApiSlice";
import * as Constant from "../../features/constant";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import format from "date-fns/format";
import CopiedTooltip from "../common/CopiedTooltip";
import {AccountCircle as AccountCircleIcon} from "@mui/icons-material";
import i18n from "i18next";
import CircularProgress from '@material-ui/core/CircularProgress';

const IdVerification = ({ useVerifyIdentityMutation }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const PATH = process.env.PUBLIC_URL;
    const handleFileInputClick = (event) => {
        event.target.parentNode.querySelector('input[type="file"]').click();
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(5);

    const [verifyIdentity, { data, isLoading, isError, isSuccess, error }] =
        useVerifyIdentityMutation();
    
    const submit = async (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("idNumber", data.idNumber);
        formData.append("dateOfBirth", data.dateOfBirth);
        // TODO append ID file
        //formData.append("idFile1", );
        //formData.append("idFile2", );
        
        await verifyIdentity({ identity: formData }).unwrap();
        if (!isDisabled) {
            setTimer(5);
            setIsDisabled(true);
        }
    };

    const identitySchema = yup.object().shape({
        name: yup.string().required(t("validation.name")),
        idNumber: yup.string().required(t("validation.idNumber"))
      });

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(identitySchema),
    });

    return (
        <Container>
            <Typography fontWeight="bold" color="white">
                ID Verification
            </Typography>
            <br/>
            <form onSubmit={handleSubmit(submit)}>
                <Grid container spacing={2}>
                    {/* Name ID field */}
                    <Grid item xs={12}>
                        <FormTextField
                            controllerName="name"
                            control={control}
                            fullWidth
                            label="Name ID"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Number ID field */}
                    <Grid item xs={12}>
                        <FormTextField
                            fullWidth
                            controllerName="idNumber"
                            control={control}
                            label="Number ID"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Date of Birth field */}
                    <Grid item xs={12}>
                        <FormTextField
                            fullWidth
                            controllerName="dateOfBirth"
                            control={control}
                            label="Date of Birth"
                            type="date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true, // Hide placeholder text "DD/MM/YYYY"
                            }}
                            // Add any necessary props for validation, onChange handlers, etc.
                        />
                    </Grid>

                    {/* Upload ID field */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1"
                        sx={{
                            color:'white',
                            fontSize:'12px'
                        }}
                        >Upload ID</Typography>
                        {/* Two upload components in the same row */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        backgroundColor: '#EDEDED',
                                        width: '358px', // Adjust the width as needed
                                        height: '150px', // Adjust the height as needed
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        position: 'relative', // Added position relative
                                    }}
                                    onClick={handleFileInputClick} // Added onClick event to handle file input click
                                >
                                    <input
                                        id="idUpload1"
                                        type="file"
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, zIndex: 1 }} // Positioned input element on top of the box
                                    />
                                    <img src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`} alt="Upload" style={{ width: '50px', height: '50px', zIndex: 0 }} />
                                    <Typography
                                    sx={{
                                        fontSize:'12px',
                                        color:'grey'
                                    }}
                                    >
                                        Browse Your File Here
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        backgroundColor: '#EDEDED',
                                        width: '358px', // Adjust the width as needed
                                        height: '150px', // Adjust the height as needed
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        position: 'relative', // Added position relative
                                    }}
                                    onClick={handleFileInputClick} // Added onClick event to handle file input click
                                >
                                    <input
                                        id="idUpload2"
                                        type="file"
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0, zIndex: 1 }} // Positioned input element on top of the box
                                    />
                                    <img src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`} alt="Upload" style={{ width: '50px', height: '50px', zIndex: 0 }} />
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'grey'
                                        }}
                                    >
                                        Browse Your File Here
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Submit button */}
                    <Grid item xs={12} sx={{paddingBottom:'10px'}}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{width:'200px',height:'46px'}}
                                type="submit"
                                disabled={isDisabled}
                                //onClick={handleClick}
                                startIcon={isDisabled && <CircularProgress size={20} />}>
                                <Typography sx={{fontWeight:'bold',fontSize:'14px'}}>
                                    SUBMIT
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid item xs={12} sx={{backgroundColor:'lightgrey', borderRadius:'5px'}}>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight: 'bold', fontSize: '12px', color: 'red'}}>
                                Please Take Note:
                            </Typography>
                            <ol style={{paddingLeft: '20px'}}>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                        Image files MUST be in jpg or png format, and their size cannot exceed 10MB.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                        Images MUST be clearly visible and readable.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={{fontSize: '12px'}}>
                                        All corners of the image must be visible.
                                    </Typography>
                                </li>
                            </ol>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1"
                                    sx={{
                                        color:'white',
                                        fontSize:'12px'
                                    }}
                        >Example</Typography>
                        {/* Two upload components in the same row */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative', // Added position relative
                                    }}
                                >
                                    <img src={PATH + `/assets/img/kyc_1.jpg`} alt="Upload" style={{ width: '358px', height: '150px', zIndex: 0, borderRadius: '5px'}} />
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'white',
                                            fontWeight:'bold'
                                        }}
                                    >
                                        Front
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                {/* First upload component */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative', // Added position relative
                                    }}
                                >
                                    <img src={PATH + `/assets/img/kyc_2.jpg`} alt="Upload" style={{ width: '358px', height: '150px', zIndex: 0, borderRadius: '5px' }} />
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                            color:'white',
                                            fontWeight:'bold'
                                        }}
                                    >
                                        Selfie with ID
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </Container>
    );
};

export default IdVerification;
