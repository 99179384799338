import React, { useEffect } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Container, Typography, Button, Box } from "@mui/material";
import FormTextField from "../form/FormTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ useUpdateProfileMutation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const passwordSchema = yup.object().shape({
    currentPassword: yup.string().required(t("validation.currentpassword")),
    newPassword: yup.string().required(t("validation.newpassword")),
    reEnterPassword: yup
      .string()
      .required(t("validation.reenterpassword"))
      .oneOf([yup.ref("newPassword"), null], t("validation.passwordnotmatch")),
  });

  const [updateProfile, { data, isLoading, isError, isSuccess, error }] =
    useUpdateProfileMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const submitPassword = async (data) => {
    let formData = {
      currentPassword: data?.currentPassword,
      password: data?.newPassword,
    };
    await updateProfile(formData).unwrap();
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.changepasswordpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.data.message,
          variant: "error",
        })
      );
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {t("walletcard.changepassword")}
      </Typography>
      <Box display="flex" flexDirection="column" >
        <form onSubmit={handleSubmit(submitPassword)}>
          <FormTextField
            label={t("walletcard.changepasswordpage.currentpassword")}
            control={control}
            controllerName="currentPassword"
            defaultValue={""}
            type="password"
            size="small"
          />
          <FormTextField
            label={t("walletcard.changepasswordpage.newpassword")}
            control={control}
            controllerName="newPassword"
            defaultValue={""}
            type="password"
            size="small"
          />
          <FormTextField
            label={t("walletcard.changepasswordpage.reenterpassword")}
            control={control}
            controllerName="reEnterPassword"
            defaultValue={""}
            type="password"
            size="small"
          />
          <Box alignSelf="center" mb="15px" mt="15px">
            <Button variant="contained" type="submit">
              {t("walletcard.submit")}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ChangePassword;
