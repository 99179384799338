import { useNavigate } from "react-router-dom";
import {
  Check,
  Email,
  Facebook,
  Instagram,
  PhoneInTalk,
  SupportAgent,
  Telegram,
  WhatsApp,
  YouTube,
  ArrowBack,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const About = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {mobileView && (
        <>
          <Grid container justify="center" pt={1} pb={5}>
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("aboutus.about.title")}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <>
        <Container mt={mobileView ? 0 : null}>
          <Box py={!mobileView && 4}>
            {/* About us */}
            <Typography
              variant="h6"
              component="h6"
              fontWeight={800}
              color={theme.palette.primary.main}
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.title") }}
            >
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.firstdesc") }}>
            </Typography><br/>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.seconddesc") }}>
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.firstsubtitle") }}>
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.thirddesc") }}>
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.secondsubtitle") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.fourthdesc") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.thirdsubtitle") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.fifthdesc") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.fourthsubtitle") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.sixthdesc") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.seventhdesc") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.sixthsubtitle") }}>
            </Typography>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.eightdesc") }}>
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.seventhsubtitle") }}>
            </Typography>
            <Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.ninthdesc") }}>
            </Typography><br/>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.tenthdesc") }}>
            </Typography><br/>
<Typography variant="body2" component="div" color="white"
              dangerouslySetInnerHTML={{ __html: t("aboutus.about.eleventhdesc") }}>
            </Typography>
          </Box>
        </Container>
      </>
    </Box>
  );
};

export default About;
