import React, { useState, useEffect } from 'react';
import { Card, Modal, IconButton, useMediaQuery, useTheme, CardContent, Grid, Typography, CardMedia, List, ListItem, ListItemText, Box, Checkbox, FormControlLabel, Link  } from '@mui/material';
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import MobilePopupCarousel from "./MobilePopupCarousel";

const PopupBanner = ({data}) => {
  const [popupBannerData, setPopupBannerData] = useState([]); 
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(null); 
  const [dontShowAgain, setDontShowAgain] = useState(false); 
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const closePopup = () => setPopupOpen(false);
  const openPopup = () => setPopupOpen(true);
  const [popupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    if (data?.result) { 
      setPopupBannerData(data.result);
      if (data.result.length > 0) {
        setSelectedItem(data.result[0]); 
      }
    }
  }, [data]);

  useEffect(() => {
    if (dontShowAgain) {
      localStorage.setItem('hidePopup', 'true');
    } else {
      localStorage.removeItem('hidePopup');
    }
  }, [dontShowAgain]);

  const handleListItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <Modal open={popupOpen} onClose={closePopup}>
      <Box
          sx={{
            position: "absolute",
            top: mobileView ? "50%" : "50%",
            left: "50%",
            width: mobileView ? 300 : "60%",
            transform: "translate(-50%, -50%)",
            outline: "none",
          }}
          textAlign="right"
      >
        <div sx={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
          <IconButton aria-label="close" onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        </div>
        {!mobileView ? (
      <Box>
        <Card sx={{ display: 'flex', height: '100%', aspectRatio: '15/9', overflow: 'hidden'}}>
        {selectedItem && (
         <>
          <Grid container>
            {/* Left Side: List of Titles */}
            <Grid item xs={12} md={3.5} >
              <List component="nav" sx={{ height: '100%', overflowY: 'auto' }}>
                {data?.result?.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedItem.title === item.title}
                    onClick={() => handleListItemClick(item)}
                    sx={{
                      color:'white',
                      '&.Mui-selected': {
                        backgroundColor: '#ee2842', 
                        '&:hover': {
                          backgroundColor: '#ee2842', 
                        },
                      },
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <ListItemText 
                    primary={item.title} 
                    secondary={item.date} 
                    secondaryTypographyProps={{
                      sx: {
                        color: 'white'
                        },
                      }}/>
                    </ListItem>
                ))}
              </List>
            </Grid>

            {/* Right Side: Display selected entry */}
            <Grid item xs={12} md={8.5} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto'  }}>

              <Typography variant="subtitle1" color='white' align='left' component="div" sx={{wordWrap: 'break-word'  }}>
                  {selectedItem.title}
                </Typography>

                <Link href={selectedItem.link} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  component="img"
                  image={selectedItem.imageUrl}
                  alt={selectedItem.title}
                  sx={{ width: '100%', height: 'auto', objectFit: 'cover', marginBottom: '20px', marginTop: '10px'}}
                />
                </Link>
                {/* <Typography variant="h6" color='white' align='left' component="div" sx={{ marginTop: '10px', wordWrap: 'break-word' }}>
                  {selectedItem.description}
                </Typography> */}
                <Typography variant="body1"  color='white' align='left' sx={{ marginTop: '10px', wordWrap: 'break-word'  }}
                dangerouslySetInnerHTML={{ __html: selectedItem.content }}/>
  
            </Grid>
          </Grid>
          </>
        )}
        </Card>

      </Box>
       ) : ( 
        <MobilePopupCarousel
        data={data?.result}
        slidesPerView={1}
        spaceBetween={10}
        navigation
        pagination
      />
        )}
      <FormControlLabel
          control={
            <Checkbox
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
            />
          }
          label={<Typography color='white'>Don't show again</Typography>} 
          sx={{ marginTop: '20px'}}
        />
      </Box>
    </Modal>
  );
};

export default PopupBanner;
